import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly DEFAULT_ROUTE = '/tabs/home';
  private lastTabUrl: string = this.DEFAULT_ROUTE; // Ruta por defecto

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.startsWith('/tabs/')) {
          this.lastTabUrl = event.url;
        }
      }
    });
  }

  getLastTabUrl(): string {
    return this.lastTabUrl;
  }

  async gotoAddTicketsPage() {
    await this.router.navigate([ '/add-tickets' ]);
  }

  async gotoWizard() {
    await this.router.navigate([ '/wizard' ]);
  }
}
