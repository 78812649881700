import { Tickets } from "src/core/ticket/domain/tickets";
import { TicketRepository } from "src/core/ticket/domain/ticket.repository";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'any',
})
export class GetTickets {
  constructor(private readonly ticketRepository: TicketRepository) {}

  async execute(): Promise<Tickets> {
    return this.ticketRepository.getTickets();
  }
}
