import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ApiException } from './api-exception';
import { BadRequestException } from './bad-request-exception';
import { InternalServerException } from './internal-server-exception';
import { GenericClientException } from './generic-client-exception';
import { ClientException } from './client-exception';
import { UnauthorizedException } from "src/core/shared/exceptions/unauthorized-exception";

@Injectable({
  providedIn: 'any',
})
export class ExceptionManagerService {
  constructor() {}

  manage(exception: HttpErrorResponse | ApiException | any): ApiException | ClientException {
    if (exception instanceof ApiException) {
      // RequestTooLargeException, Timeout, NoConnection, ServerIsGone, etc.
      return exception;
    }

    // otherwise, exception instanceof HttpErrorResponse
    if (exception instanceof HttpErrorResponse) {
      switch (exception.status) {
        case HttpStatusCode.Unauthorized:
          return UnauthorizedException.fromError(exception);
        case HttpStatusCode.BadRequest:
          return BadRequestException.fromError(exception);
      }

      // otherwise, internal server exception
      return InternalServerException.fromError(exception);
    }

    return GenericClientException.fromError(exception as Error);
  }
}
