import { HttpErrorResponse } from '@angular/common/http';
import { Nullable } from "src/core/shared/types/nullable.type";

export interface ClientExceptionConstructor {
  fromError(error: Error): ClientException;
}

export abstract class ClientException extends Error implements ClientExceptionConstructor {
  public originalError: Nullable<Error> = null;
  public code: Nullable<string> = null;

  protected constructor(message: string = '', error: Error) {
    const trueProto = new.target.prototype; // Almacenar el prototipo verdadero
    super(message);
    Object.setPrototypeOf(this, Object.create(trueProto)); // Crear un nuevo objeto con el prototipo verdadero
    this.name = 'ClientException';
    this.setOriginalError(error);
    this.calculateErrorCode();
  }

  fromError(error: HttpErrorResponse | Error): ClientException {
    throw new Error('El método fromError debe ser implementado en cada subclase de ClientException');
  }

  protected setOriginalError(error: HttpErrorResponse | Error): void {
    this.originalError = error;
  }

  private calculateErrorCode() {
    this.code = this.generateErrorCodeWithPrefix('C');
  }

  private generateErrorCodeWithPrefix(prefix: string): string {
    const errorCode = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    return `${prefix}-${errorCode}`;
  }
}
