import { Injectable } from "@angular/core";
import { Storable } from "src/core/shared/storage/domain/storable";
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TicketService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  constructor() {}

  async init() {
    this.readySubject.next(true);
  }

  clear(): void {
  }
}
