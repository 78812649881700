import { ErrorHandler, Injectable } from '@angular/core';
import { SentryIonicErrorLogger } from "src/app/errors/sentry-ionic-error-logger.service";
import { EventsService } from "src/core/events/events.service";
import { LoadingHelper } from "src/app/helpers/loading-helper";
import { MessageHelper } from "src/app/helpers/message-helper";
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";
import { UnauthorizedException } from "src/core/shared/exceptions/unauthorized-exception";
import { ApiException } from "src/core/shared/exceptions/api-exception";
import { ClientException } from "src/core/shared/exceptions/client-exception";
import { AuthorizationExpiredEvent } from "src/core/events/authorization-expired.event";

@Injectable({
  providedIn: 'any'
})
export class CustomErrorHandler implements ErrorHandler {
  private static readonly DISCARDING_ERRORS_WINDOW_TIME = 2000;
  private discardingErrors = false;

  constructor(
    private readonly externalErrorLogger: SentryIonicErrorLogger,
    private readonly eventsService: EventsService,
    private readonly loadingHelper: LoadingHelper,
    private readonly messageHelper: MessageHelper,
    private readonly translator: TranslatorService,
  ) {}

  async handleError(_error: any) {
    console.warn('Received unhandled error');

    // when error comes from a Promise rejection, the actual error comes inside error.rejection
    const error = _error?.rejection || _error;

    // Uncomment to see the error and the original error (if exists)
    this.printErrorToConsole(error);

    await this.tryLoCloseLoadingLayer();

    if (this.authorizationHasExpired(error)) {
      this.eventsService.publishAuthorizationExpired(new AuthorizationExpiredEvent());
      return;
    }

    if (this.discardingErrors) {
      // console.warn('Received error within discarding window time, skipping…');
      return;
    }
    this.activateSemaphoreDuring(CustomErrorHandler.DISCARDING_ERRORS_WINDOW_TIME);

    if (this.isApiError(error)) {
      await this.handleApiError(error);
      return;
    }

    // otherwise, client error
    await this.handleClientError(error);
  }

  private authorizationHasExpired(error: any): boolean {
    return error instanceof UnauthorizedException;
  }

  private isApiError(error: any): boolean {
    return error instanceof ApiException;
  }

  private async handleApiError(error: ApiException): Promise<void> {
    // if (error instanceof NoConnectionException) {
    //   await this.displayNoConnectionToast();
    //   return;
    // }
    //
    // if (error instanceof ServerIsGoneException) {
    //   await this.displayServerIsGoneToast();
    //   return;
    // }

    // log error (asynchronously) to external logger service (Crashlytics, Bugsnag, Sentry, etc.)
    this.externalErrorLogger.log(error).then().catch(console.error);

    console.log("error.message, error.code");
    console.log(error.message, error.code);

    // handle specific API errors, already sent to Sentry
    // …

    await this.displayErrorToastWithCode(error.code);
  }

  private async displayErrorToastWithCode(code: string | null): Promise<void> {
    const message = await this.translator.translate('MESSAGES.UNHANDLED_API_ERROR', { code });
    await this.messageHelper.showError(message);
  }

  private printErrorToConsole(error: Error | ApiException | ClientException) {
    console.log('Wrapped error');
    console.error(error);
    if (error instanceof ApiException || error instanceof ClientException) {
      if (error.originalError) {
        console.log(`Original error, with code ${error.code}`);
        console.error(error.originalError);
      }
    }
  }

  private activateSemaphoreDuring(time: number) {
    this.discardingErrors = true;
    setTimeout(() => {
      this.discardingErrors = false;
    }, time);
  }

  /**
   * In case any error occurs, we ensure that the loading layer is always removed
   * so that the user can continue to use the application.
   */
  private async tryLoCloseLoadingLayer() {
    await this.loadingHelper.end();
  }

  private async handleClientError(error: Error | ClientException): Promise<void> {
    // Log error (asynchronously) to external logger service (Crashlytics, Bugsnag, Sentry, etc.)
    // If error reaches here, it means it was not controlled in anywhere before, so we log it.
    const code = this.generateErrorCodeWithPrefix('C');
    this.externalErrorLogger.log(error, code).then().catch();
    const message = await this.translator.translate('MESSAGES.UNHANDLED_CLIENT_ERROR', { code });
    await this.messageHelper.showError(message);
  }

  private generateErrorCodeWithPrefix(prefix: string): string {
    const errorCode = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    return `${prefix}-${errorCode}`;
  }
}
