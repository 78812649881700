import { Injectable } from '@angular/core';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Initializable } from './initializable';
import { ApiService } from "src/core/api/domain/api.service";
import { SessionService } from "src/core/session/domain/session.service";
import { ExperienceService } from "src/core/experiences/domain/experience.service";
import { MissionService } from "src/core/mission/domain/mission.service";
import { MediaService } from "src/core/media/domain/media.service";
import { StepService } from "src/core/step/domain/step.service";
import { PrizeService } from "src/core/prize/domain/prize.service";
import { QuizService } from "src/core/quiz/domain/quiz.service";
import { PrivacyPolicyService } from "src/core/privacy-policy/domain/privacy-policy.service";
import { TicketService } from "src/core/ticket/domain/ticket.service";

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private readonly apiService: ApiService,
    private readonly sessionService: SessionService,
    private readonly experiencesService: ExperienceService,
    private readonly missionService: MissionService,
    private readonly mediaService: MediaService,
    private readonly stepService: StepService,
    private readonly prizeService: PrizeService,
    private readonly quizService: QuizService,
    private readonly ticketService: TicketService,
    private readonly privacyPolicyService: PrivacyPolicyService,
  ) {
  }

  static async waitFor(...services: Initializable[]): Promise<void> {
    const sources: Observable<boolean>[] = [];
    services.map((service) => {
      sources.push(
        service.ready$.pipe(
          filter((value) => value), // filter only "true" values
          take(1), // take the first true value (when service is ready)
        ),
      );
    });

    return new Promise<void>(async (resolve, reject) => {
      try {
        await lastValueFrom(forkJoin(sources));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async initAll(): Promise<void> {
    await this.apiService.init();
    await this.sessionService.init();
    await this.experiencesService.init();
    await this.mediaService.init();
    await this.stepService.init();
    await this.missionService.init();
    await this.prizeService.init();
    await this.privacyPolicyService.init();
    await this.quizService.init();
    await this.ticketService.init();
  }
}
