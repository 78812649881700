import { LocalDate } from "src/core/shared/date/local-date";

export type TicketDetailPrimitives = {
  id: string;
  reservationId: string;
  productName: string;
  date: string;
  email: string;
  experienceId: string;
  qrCode: string;
  barcode: string;
}

export class TicketDetail {
  constructor(
    public readonly id: string,
    public readonly reservationId: string,
    public readonly productName: string,
    public readonly date: LocalDate,
    public readonly email: string,
    public readonly experienceId: string,
    public readonly qrCode: string,
    public readonly barcode: string,
  ) {}

  static fromPrimitives(primitives: TicketDetailPrimitives) {
    const date = LocalDate.fromIsoString(primitives.date);
    if (date === null) {
      throw new Error();
    }

    return new TicketDetail(
      primitives.id,
      primitives.reservationId,
      primitives.productName,
      date,
      primitives.email,
      primitives.experienceId,
      primitives.qrCode,
      primitives.barcode,
    );
  }


  toPrimitives(): TicketDetailPrimitives {
    return {
      id: this.id,
      reservationId: this.reservationId,
      productName: this.productName,
      date: this.date.atom,
      email: this.email,
      experienceId: this.experienceId,
      qrCode: this.qrCode,
      barcode: this.barcode,
    };
  }
}
