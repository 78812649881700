import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from "src/core/session/domain/session.service";

export const authGuard: CanActivateFn = async () => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  const isLogged = await sessionService.isAuthenticatedPromise();
  if (isLogged) {
    return true;
  } else {
    await router.navigate([ '/welcome' ]);
    return false;
  }
};
