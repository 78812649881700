import { HttpErrorResponse } from '@angular/common/http';
import { ApiException, ApiExceptionConstructor } from "src/core/shared/exceptions/api-exception";

export class TicketNotAvailableException extends ApiException implements ApiExceptionConstructor {
  constructor(message: string = '', error: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'TicketNotAvailableException';
  }

  static fromError(error: HttpErrorResponse | Error): TicketNotAvailableException {
    return new this(error.message, error);
  }
}
