import { Injectable } from "@angular/core";
import { ApiService } from "src/core/api/domain/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "src/core/session/domain/session.service";
import { lastValueFrom } from "rxjs";
import { QuizRepository } from "src/core/quiz/domain/quiz.repository";
import { Quiz } from "src/core/quiz/domain/quiz";
import { ApiGetQuizResponse } from "src/core/quiz/infrastructure/api-get-quiz-response";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";

@Injectable({
  providedIn: 'any',
})
export class HttpQuizRepository extends QuizRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async getQuiz(quizId: string): Promise<Quiz> {
    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.loadResponse(quizId);
        resolve(this.parseApiLoadResponse(apiResponse));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadResponse(quizId: string): Promise<ApiGetQuizResponse> {
    // const mocked = true;
    // if (mocked) {
    //   return this.mockedResponse();
    // }

    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const url = `${baseUrl}/quizzes/${quizId}`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetQuizResponse>(url, options));
  }

  private mockedResponse() {
    return {
      "quiz": {
        "id": "39zuBMTRMCXGhO1UycBdsbOCJXi0rUmMujAuZof1IMaC3O",
        "name": {
          "ca": "¿Qué sabes de Casa Battló?",
          "en": "¿Qué sabes de Casa Battló?",
          "it": "¿Qué sabes de Casa Battló?",
          "es": "¿Qué sabes de Casa Battló?",
        },
        "description": {
          "ca": "Pon a prueba tu conocimiento, desmuestra todo lo que has aprendido y gana Gaudí Points para tus premios",
          "en": "Pon a prueba tu conocimiento, desmuestra todo lo que has aprendido y gana Gaudí Points para tus premios",
          "it": "Pon a prueba tu conocimiento, desmuestra todo lo que has aprendido y gana Gaudí Points para tus premios",
          "es": "Pon a prueba tu conocimiento, desmuestra todo lo que has aprendido y gana Gaudí Points para tus premios",
        },
        "questions": [
          {
            "id": "zxFPZHWtL5uYqCwkUZbHZ8TlxUggUlI9ZCj7f3U1Mf1g",
            "text": {
              "ca": "¿Qué técnica decorativa es característica de Casa Batlló y se usa en el patio exterior?",
              "en": "¿Qué técnica decorativa es característica de Casa Batlló y se usa en el patio exterior?",
              "it": "¿Qué técnica decorativa es característica de Casa Batlló y se usa en el patio exterior?",
              "es": "¿Qué técnica decorativa es característica de Casa Batlló y se usa en el patio exterior?",
            },
            "answers": [
              {
                "id": "ROYu8GFBwfG9SJeuLgcBlUpLcm9ujosDjcDMszQu7XfxXTqk",
                "text": {
                  "ca": "Frescos pintados",
                  "en": "Frescos pintados",
                  "it": "Frescos pintados",
                  "es": "Frescos pintados",
                },
                "isValid": false,
              },
              {
                "id": "QGliwrH32TmQSWsDzTEpSjRHA0sezIEECjZHAYFWpSYZSBD",
                "text": {
                  "ca": "Mosaicos",
                  "en": "Mosaicos",
                  "it": "Mosaicos",
                  "es": "Mosaicos",
                },
                "isValid": false,
              },
              {
                "id": "DMIZF0FRaT1Guk5sKZC8DsDMcJtJKT3nHEMH7rtoYSnX",
                "text": {
                  "ca": "Trencadís",
                  "en": "Trencadís",
                  "it": "Trencadís",
                  "es": "Trencadís",
                },
                "isValid": true,
              },
            ],
          },
          {
            "id": "QqjsnwfMkFOQuwKTQEHEZCj8IAQSmiGHgjCZRiWnc5Kco4",
            "text": {
              "ca": "¿Qué inspiró la forma de la escalera del vestíbulo de la familia Batlló?",
              "en": "¿Qué inspiró la forma de la escalera del vestíbulo de la familia Batlló?",
              "it": "¿Qué inspiró la forma de la escalera del vestíbulo de la familia Batlló?",
              "es": "¿Qué inspiró la forma de la escalera del vestíbulo de la familia Batlló?",
            },
            "answers": [
              {
                "id": "G7yt41h4f9xU1qsRSxeta4U4XtpnhPXi1zHxehR0iXruBg",
                "text": {
                  "ca": "La columna vertebral de un animal",
                  "en": "La columna vertebral de un animal",
                  "it": "La columna vertebral de un animal",
                  "es": "La columna vertebral de un animal",
                },
                "isValid": true,
              },
              {
                "id": "nDs7JSoImLHOkcYGikztAgTo3F34HYetkLH0eUrcjrF8W",
                "text": {
                  "ca": "Un río",
                  "en": "Un río",
                  "it": "Un río",
                  "es": "Un río",
                },
                "isValid": false,
              },
              {
                "id": "41bUDbuBghaEHP3u5HweTXnSa7tq7hzpcASYaueBtEBIA5",
                "text": {
                  "ca": "Un árbol",
                  "en": "Un árbol",
                  "it": "Un árbol",
                  "es": "Un árbol",
                },
                "isValid": false,
              },
            ],
          },
          {
            "id": "nRzFL1FzJUZS2rtAH77TAntojuRHq2TLmFmnHoKTnBhAR",
            "text": {
              "ca": "¿Qué sistema innovador de ventilación diseñó Gaudí para Casa Batlló?",
              "en": "¿Qué sistema innovador de ventilación diseñó Gaudí para Casa Batlló?",
              "it": "¿Qué sistema innovador de ventilación diseñó Gaudí para Casa Batlló?",
              "es": "¿Qué sistema innovador de ventilación diseñó Gaudí para Casa Batlló?",
            },
            "answers": [
              {
                "id": "XmJiGmFQcJzhDOCaKIWjUY1C7zI0GFWxTRbFdrSxPh8BTx",
                "text": {
                  "ca": "Paneles abatibles que imitan branquias",
                  "en": "Paneles abatibles que imitan branquias",
                  "it": "Paneles abatibles que imitan branquias",
                  "es": "Paneles abatibles que imitan branquias",
                },
                "isValid": true,
              },
              {
                "id": "GmOh9MfaF5EtkETafYmiaQsbjhB3CzZSyRu2fL7UZqFBq",
                "text": {
                  "ca": "Aire acondicionado moderno",
                  "en": "Aire acondicionado moderno",
                  "it": "Aire acondicionado moderno",
                  "es": "Aire acondicionado moderno",
                },
                "isValid": false,
              },
              {
                "id": "97ahgZhjZtw2HeEuy4CpnhYgurKF10iBXTwxuAWhLH4DuzY",
                "text": {
                  "ca": "Ventanas automáticas",
                  "en": "Ventanas automáticas",
                  "it": "Ventanas automáticas",
                  "es": "Ventanas automáticas",
                },
                "isValid": false,
              },
            ],
          },
          {
            "id": "XmWcGUPHJafBXs2PTWwhYXh7GU3zsMBt3t0RfxGH8js7a",
            "text": {
              "ca": "¿Qué función cumplía el patio de luces de la Casa Batlló?",
              "en": "¿Qué función cumplía el patio de luces de la Casa Batlló?",
              "it": "¿Qué función cumplía el patio de luces de la Casa Batlló?",
              "es": "¿Qué función cumplía el patio de luces de la Casa Batlló?",
            },
            "answers": [
              {
                "id": "bdUXs4Udec5I4xhEXHJ7Fp2f2eSZrunWHdmia7hq5S1w",
                "text": {
                  "ca": "Distribuir la luz y la ventilación",
                  "en": "Distribuir la luz y la ventilación",
                  "it": "Distribuir la luz y la ventilación",
                  "es": "Distribuir la luz y la ventilación",
                },
                "isValid": true,
              },
              {
                "id": "PJHEAHj3fpacL3SYZC5U0jUBni4U40uMsa3HlpilaSD1",
                "text": {
                  "ca": "Tender la ropa",
                  "en": "Tender la ropa",
                  "it": "Tender la ropa",
                  "es": "Tender la ropa",
                },
                "isValid": false,
              },
              {
                "id": "WRqigLIeBT9aS8BH5quPkF4kfjgIX1fEmcyKtelUz5u9cla",
                "text": {
                  "ca": "Solo decoración",
                  "en": "Solo decoración",
                  "it": "Solo decoración",
                  "es": "Solo decoración",
                },
                "isValid": false,
              },
            ],
          },
          {
            "id": "alZheMsgfGGtwzHBLuzWUZ1cLkS4fRDULTjDcMqi03Fee",
            "text": {
              "ca": "¿En qué años se construyó Casa Batlló?",
              "en": "¿En qué años se construyó Casa Batlló?",
              "it": "¿En qué años se construyó Casa Batlló?",
              "es": "¿En qué años se construyó Casa Batlló?",
            },
            "answers": [
              {
                "id": "1ZT1c2WTEZFWxIGnu4mcw4Hz8hobFbUaLTmLcKfbnuRj",
                "text": {
                  "ca": "Entre 1904 y 1906",
                  "en": "Entre 1904 y 1906",
                  "it": "Entre 1904 y 1906",
                  "es": "Entre 1904 y 1906",
                },
                "isValid": true,
              },
              {
                "id": "LjOcWnhlEfm2SRlC0AFReFRYCM7tpnc8qu20TYCYAFWOtyb",
                "text": {
                  "ca": "Entre 1901 y 1903",
                  "en": "Entre 1901 y 1903",
                  "it": "Entre 1901 y 1903",
                  "es": "Entre 1901 y 1903",
                },
                "isValid": false,
              },
              {
                "id": "0ZRHXpUAKslbcz9H2Df8cGyTPzSw3h8gs9oSL3HrDUytd",
                "text": {
                  "ca": "Entre 1910 y 1912",
                  "en": "Entre 1910 y 1912",
                  "it": "Entre 1910 y 1912",
                  "es": "Entre 1910 y 1912",
                },
                "isValid": false,
              },
            ],
          },
          {
            "id": "l1oT9Qs0AS7oSBPskAh8oFxacqZuq3TbrupyC0JhzKSXiLd",
            "text": {
              "ca": "¿Qué querían hacer los señores Batlló con el edificio original?",
              "en": "¿Qué querían hacer los señores Batlló con el edificio original?",
              "it": "¿Qué querían hacer los señores Batlló con el edificio original?",
              "es": "¿Qué querían hacer los señores Batlló con el edificio original?",
            },
            "answers": [
              {
                "id": "X89F4yHwyIO5hl5uBF7biYMiQntpRiz7FD0hZXt91I2bU9W",
                "text": {
                  "ca": "Derribarlo y construir uno nuevo",
                  "en": "Derribarlo y construir uno nuevo",
                  "it": "Derribarlo y construir uno nuevo",
                  "es": "Derribarlo y construir uno nuevo",
                },
                "isValid": true,
              },
              {
                "id": "neKH42IzquE7uyJFYptkGsA2uoATRZiq1ILbCxLTloURi5D",
                "text": {
                  "ca": "Alquilarlo completamente",
                  "en": "Alquilarlo completamente",
                  "it": "Alquilarlo completamente",
                  "es": "Alquilarlo completamente",
                },
                "isValid": false,
              },
              {
                "id": "4GwhDOHwqfO4i75FoRHw0sXPua3TqnsZDIBUXwiJgHEbHXG",
                "text": {
                  "ca": "Vivir en él tal como estaba",
                  "en": "Vivir en él tal como estaba",
                  "it": "Vivir en él tal como estaba",
                  "es": "Vivir en él tal como estaba",
                },
                "isValid": false,
              },
            ],
          },
        ],
      },
    };
  }

  private parseApiLoadResponse(response: ApiGetQuizResponse): Quiz {
    const quiz = Quiz.fromPrimitives({
      id: response.quiz.id,
      name: response.quiz.name,
      description: response.quiz.description,
      questions: response.quiz.questions,
    });
    if (quiz === null) {
      throw new Error('Quiz not valid');
    }

    return quiz;
  }
}
