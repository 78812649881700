import { Content } from "src/core/content/domain/content";
import { ContentRepository } from "src/core/content/domain/content.repository";
import { ApiService } from "src/core/api/domain/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "src/core/session/domain/session.service";
import { lastValueFrom } from "rxjs";
import { ApiGetContentResponse } from "src/core/content/infrastructure/api-get-content-response";
import { Injectable } from "@angular/core";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";

@Injectable({
  providedIn: 'any',
})
export class HttpContentRepository extends ContentRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async get(id: string, language: string): Promise<Content> {
    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.loadResponse(id, language);
        resolve(this.parseApiLoadResponse(apiResponse));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadResponse(id: string, language: string): Promise<ApiGetContentResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const apiToken = this.sessionService.getApiToken();

    const url = `${baseUrl}/contents/${id}/${language}`;
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);

    const options = {
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetContentResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetContentResponse): Content {
    return Content.fromPrimitives({
      id: response.content.id,
      media: response.content.media,
    });
  }
}
