import { Ticket } from "src/core/ticket/domain/ticket";

export class Tickets {
  constructor(public readonly tickets: Array<Ticket> = []) {}

  toArray(): Array<Ticket> {
    return this.tickets;
  }

  toPrimitives() {
    return this.tickets.map(ticket => ticket.toPrimitives());
  }
}
