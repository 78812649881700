import { LocalDate } from "src/core/shared/date/local-date";

export type TicketPrimitives = {
  id: string;
  reservationId: string;
  productName: string;
  date: string;
  status: number;
}

export class Ticket {
  constructor(
    public readonly id: string,
    public readonly reservationId: string,
    public readonly productName: string,
    public readonly date: LocalDate,
    public readonly status: number,
  ) {}

  static fromPrimitives(primitives: TicketPrimitives) {
    const date = LocalDate.fromIsoString(primitives.date);
    if (date === null) {
      throw new Error();
    }

    return new Ticket(primitives.id, primitives.reservationId, primitives.productName, date, primitives.status);
  }


  toPrimitives(): TicketPrimitives {
    return {
      id: this.id,
      reservationId: this.reservationId,
      productName: this.productName,
      date: this.date.atom,
      status: this.status,
    };
  }
}
