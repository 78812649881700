import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { ApiService } from "src/core/api/domain/api.service";
import { PrizeRepository } from "src/core/prize/domain/prize.repository";
import { ApiGetPrizesResponse } from "src/core/prize/infrastructure/api-get-prizes-response";
import { Prize } from "src/core/prize/domain/prize";
import { Prizes } from "src/core/prize/domain/prizes";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";

@Injectable({
  providedIn: 'any',
})
export class HttpPrizeRepository extends PrizeRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async load(): Promise<Prizes> {
    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.loadResponse();
        resolve(this.parseApiLoadResponse(apiResponse));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async redeem(prizeId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.apiAccessService.renewApiAccessIfNeeded();

        const baseUrl = this.apiService.currentApi().urlVisitor;
        const apiToken = this.sessionService.getApiToken();

        const url = `${baseUrl}/awards/${prizeId}/redeem`;
        const apiVersion = '1.0';
        const appVersion = '1.0';
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Accept', 'text/plain');
        httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
        httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
        httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);

        const options = {
          headers: httpHeaders,
        };
        const body = {};
        await lastValueFrom(this.http.post(url, body, options));
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadResponse(): Promise<ApiGetPrizesResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const apiToken = this.sessionService.getApiToken();

    const url = `${baseUrl}/awards`;
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);

    const options = {
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetPrizesResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetPrizesResponse): Prizes {
    const prizes = response.awards.map(prizeData => {
      return Prize.fromPrimitives({
          id: prizeData.id,
          type: prizeData.type,
          title: prizeData.name,
          shortDescription: prizeData.shortDescription,
          longDescription: prizeData.longDescription,
          imageUrl: prizeData.imageUrl,
          points: prizeData.points,
        },
      )
    });

    return new Prizes(prizes);
  }
}
