import { Tickets } from "src/core/ticket/domain/tickets";
import { TicketDetail } from "src/core/ticket/domain/ticket-detail";

export abstract class TicketRepository {
  abstract getTickets(): Promise<Tickets>;

  abstract getTicket(ticketId: string, reservationId: string): Promise<TicketDetail>;

  abstract add(ticketId: string, reservationId: string): Promise<void>;

  abstract delete(ticketId: string): Promise<void>;
}
